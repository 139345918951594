import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import Footer from "./components/Footer";
import { Typography } from "@mui/material";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Typography
      variant="h4"
      component="h1"
      sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
      >
      Commin Song
      </Typography>
    </div>
   {/* <App />
    <Footer />*/}
  </ThemeProvider>
);
